@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 12px !important
}

::-webkit-scrollbar-thumb {
  background-color: tomato;
  border-radius: 7px
}

@tailwind base;
@tailwind components;
@tailwind utilities;